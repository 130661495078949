import LocalizedStrings from 'react-localization';

const cartSuccessContent = new LocalizedStrings({
    en: {
        heading: 'Thank you {firstName}!',
        description: 'Purchase completed. Check your inbox for your confirmation email and Certificate of Insurance.',
        accountCreation:
            '<strong>The purchase has been added to your Flip account.</strong><br/><p>To manage your cover purchases go to your dashboard.<br/>To make a claim, you can do so by downloading the mobile App.</p>',
        accessAccountCTA: ' Go to dashboard',
        downloadTheAppCta: 'Download the app<br/>for easy access to your account',
        productsCta: 'Continue browsing',
        appStoreActionText: 'Download on the',
        appStoreStoreName: 'App Store',
        playStoreActionText: 'Get it on',
        playStoreStoreName: 'Google Play',
    },
});

export default cartSuccessContent;
