import { datadogRum } from '@datadog/browser-rum';
import { METADATA_CATEGORY } from '../../business-logic/models/Metadata';
import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const updateActivitiesMetadata = async ({
    accessToken,
    activityMetadata,
    axios,
}: {
    accessToken: string;
    activityMetadata: {
        paymentId: string;
        activities: string[];
    };
} & ServiceArgs): Promise<void> => {
    try {
        assertAccessTokenValidity(accessToken);
        await withRetriesAsync(() =>
            axios.post(
                `${baseApiPath}/api/v1/analytics-metadata/metadata`,
                {
                    items: [
                        {
                            entityId: activityMetadata.paymentId,
                            metadataCategory: METADATA_CATEGORY.purchaseActivities,
                            data: {
                                activities: activityMetadata.activities,
                            },
                        },
                    ],
                },
                toHeaders(accessToken),
            ),
        );
    } catch (error) {
        datadogRum.addError(error, {
            serviceName: 'analyticsMetadata',
            description: 'error in analytics metadata call',
        });
    }
};

export default asService(updateActivitiesMetadata);
