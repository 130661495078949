import { AuthState } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useContext, createContext, useEffect, useMemo, useRef, useReducer, useCallback } from 'react';

import UserDetails from '../../models/UserDetails';
import UserService from '../../../services/UserService';
import UserPreferences from '../../models/UserPreferences';
import Analytics from '../../../analytics/Analytics';
import { LoadingSpinnerOverlay } from '../../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import TimezoneByState from '../../../utils/constants/TimezoneByState';
import useCreateLazyDependency from '../../../hooks/lazy-dependency/useCreateLazyDependency';
import PaymentService from '../../../services/payment-service/PaymentService';
import { initialState, IUserContext, UserDispatchAction, UserState } from './UserContextTypes';
import CustomerService from '../../../services/customer-service/CustomerService';
import StorageKeys from '../../../utils/constants/StorageKeys';
import { AuthenticationMethod } from '../../../analytics/Analytics.d';
import PersonService from '../../../services/person-service/PersonService';
import { CustomerResponseProfile } from '../../models/CustomerResponse';

const env = import.meta.env.VITE_NETLIFY_ENV;

export const UserContext = createContext<any>(initialState);

export const useUser = (): IUserContext => {
    const context: IUserContext = useContext(UserContext);
    if (typeof context === 'undefined') {
        throw new Error('User Context must be used within the UserProvider');
    }
    return context;
};

const reducer = (state: UserState, action: UserDispatchAction): UserState => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_INITIALISED':
            return { ...state, initialised: action.payload };
        case 'SET_ACCESS_TOKEN':
            return { ...state, accessToken: action.payload };
        case 'SET_USER_DETAILS':
            return { ...state, userDetails: action.payload };
        case 'SET_USER_ATTRIBUTE':
            return { ...state, userDetails: { ...state.userDetails, ...action.payload } };
        case 'SET_USER_PREFERENCES':
            return { ...state, userPreferences: action.payload };
        case 'UPDATE_USER_PREFERENCE':
            return { ...state, userPreferences: { ...state.userPreferences, ...action.payload } };
        case 'SET_USER_VARIABLES':
            return { ...state, variables: action.payload };
        case 'SET_QUIZ_ANSWER':
            return { ...state, onboardingQuizAnswers: { ...state.onboardingQuizAnswers, ...action.payload } };
        case 'SET_DEPENDANTS':
            return { ...state, dependants: action.payload };
        case 'ADD_DEPENDANT':
            return {
                ...state,
                dependants: [...state.dependants.filter((d) => d.personId !== action.payload.personId), action.payload],
            };
        default:
            throw new Error();
    }
};

export const UserProvider: React.FC = (props) => {
    const { oktaAuth } = useOktaAuth();

    const [state, dispatch] = useReducer(reducer, initialState);
    const isInitialising = useRef(false);

    // ***************** Setters *****************

    const setLoading = (loading: boolean) => {
        dispatch({ type: 'SET_LOADING', payload: loading });
    };

    const setInitialised = (initialised: boolean) => {
        dispatch({ type: 'SET_INITIALISED', payload: initialised });
    };

    const setAccessToken = (accessToken: string) => {
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
    };

    const setUserDetails = (userDetails: UserDetails) => {
        dispatch({ type: 'SET_USER_DETAILS', payload: userDetails });
    };

    // Update user details values
    const setUserDetailsByAttr = (attr: keyof UserDetails, value?: string | undefined | string[]) => {
        dispatch({ type: 'SET_USER_ATTRIBUTE', payload: { [attr]: value } });
    };

    const setUserBankDetails = (accountName: string, accountNumber: string, bsb: string) => {
        dispatch({
            type: 'SET_USER_ATTRIBUTE',
            payload: { bankDetails: { accountName, accountNumber, bsb } },
        });
    };

    const setUserPreferences = (userPreferences: UserPreferences) => {
        dispatch({ type: 'SET_USER_PREFERENCES', payload: userPreferences });
    };

    const updateUserPreference = (id: string, checked: boolean) => {
        dispatch({ type: 'UPDATE_USER_PREFERENCE', payload: { [id]: checked } });
    };

    const setUserVariables = (variables: Record<string, unknown>) => {
        dispatch({ type: 'SET_USER_VARIABLES', payload: variables });
    };

    const setQuizAnswer = (answer: Record<string, boolean>) => {
        dispatch({ type: 'SET_QUIZ_ANSWER', payload: answer });
    };

    const setDependants = (dependants: UserDetails[]) => {
        dispatch({ type: 'SET_DEPENDANTS', payload: dependants });
    };

    const addDependants = (dependants: UserDetails) => {
        dispatch({ type: 'ADD_DEPENDANT', payload: dependants });
    };

    // ***************** Logout method *****************

    const logout = useCallback(async () => {
        // Show loader
        setLoading(true);

        // Track sign out and reset segment user
        Analytics.trackSignedOut({ username: state.userDetails.email });

        // Trigger okta sign out process
        await oktaAuth.signOut();
    }, [oktaAuth, state.userDetails.email]);

    // ***************** Fetch and set *****************

    const fetchAndSetUserPreferences = useCallback(async (accessToken: string): Promise<void> => {
        const customer = await CustomerService.getCustomer({
            accessToken,
        });

        if (customer === null) {
            return;
        }

        setUserPreferences(customer.userPreferences);
    }, []);

    const initialiseAndSetUserDetails = useCallback(
        async (accessToken: string, profile: Partial<CustomerResponseProfile>): Promise<void> => {
            try {
                // Initialise customer
                const { isNewCustomer } = await CustomerService.initialiseCustomer({
                    accessToken,
                });

                // Initialise customer profile
                const { userDetails, userPreferences, variables } = await CustomerService.updateCustomerProfile({
                    accessToken,
                    profile,
                });

                setUserDetails(userDetails);

                setUserPreferences(userPreferences);

                if (variables === null) {
                    setUserVariables({
                        announcementViewCount: {},
                    });
                    CustomerService.updateCustomerVariables({
                        accessToken: accessToken!,
                        variables: {
                            announcementViewCount: {},
                        },
                    });
                } else {
                    setUserVariables(variables);
                }

                const authenticationMethod = sessionStorage.getItem(
                    StorageKeys.AUTHENTICATION_METHOD,
                ) as AuthenticationMethod | null;

                // If authentication method exists, this user just signed in or registered
                if (!authenticationMethod) {
                    Analytics.identify(undefined, userDetails.id);
                } else {
                    sessionStorage.removeItem(StorageKeys.AUTHENTICATION_METHOD);

                    const userInfo = {
                        first_name: userDetails.firstName,
                        last_name: userDetails.lastName,
                        email: userDetails.email,
                        username: userDetails.email as string,
                        createdAt: new Date().toISOString(),
                    };

                    Analytics.identify(userInfo, userDetails.id);

                    // If new user, track signed up
                    if (isNewCustomer) {
                        Analytics.trackSignedUp({
                            ...userInfo,
                            ...(authenticationMethod ? { authenticationMethod } : {}),
                        });
                    }
                }
            } catch (e) {
                // if unable to get okta user force sign out user to refresh okta session keys
                logout();
                throw e;
            }
        },
        [logout],
    );

    const fetchAndSetUserDetails = useCallback(
        async (accessToken: string): Promise<void> => {
            try {
                const customer = await CustomerService.getCustomer({
                    accessToken,
                });

                if (customer === null) {
                    // Customer is new, to be initialised in platform after profile completed
                    // For now use profile details in Okta for prefill and launchdarkly context
                    const oktaUser = await oktaAuth.getUser();

                    setUserDetails({
                        id: oktaUser.sub,
                        email: oktaUser.email,
                        personId: null,
                        firstName: oktaUser.given_name,
                        lastName: oktaUser.family_name,
                    });

                    return;
                }

                const { userDetails, userPreferences, variables } = customer;

                setUserDetails(userDetails);

                setUserPreferences(userPreferences);

                if (variables === null) {
                    setUserVariables({
                        announcementViewCount: {},
                    });
                    CustomerService.updateCustomerVariables({
                        accessToken: accessToken!,
                        variables: {
                            announcementViewCount: {},
                        },
                    });
                } else {
                    setUserVariables(variables);
                }

                const authenticationMethod = sessionStorage.getItem(
                    StorageKeys.AUTHENTICATION_METHOD,
                ) as AuthenticationMethod | null;

                // If authentication method exists, this user just signed in or registered
                if (!authenticationMethod) {
                    Analytics.identify(undefined, userDetails.id);
                } else {
                    sessionStorage.removeItem(StorageKeys.AUTHENTICATION_METHOD);

                    Analytics.identify(undefined, userDetails.id);
                    Analytics.trackSignedIn({
                        username: userDetails.email,
                        ...(authenticationMethod ? { authenticationMethod } : {}),
                    });
                }
            } catch (e) {
                // if unable to get okta user force sign out user to refresh okta session keys
                logout();
                throw e;
            }
        },
        [logout],
    );

    const fetchAndSetDependants = useCallback(async (accessToken: string): Promise<void> => {
        const { dependants } = await PersonService.getDependants({ accessToken });

        setDependants(dependants);
    }, []);

    const fetchCreditBalance = useCallback(async (): Promise<number> => {
        const accessToken = oktaAuth.getAccessToken();
        if (typeof accessToken === 'undefined') throw new Error('fetchCreditBalance: Access token required');

        const creditBalance = await PaymentService.getCreditBalance({ accessToken });
        return creditBalance;
    }, [oktaAuth]);

    const fetchTotalPurchases = useCallback(async (): Promise<number> => {
        const accessToken = oktaAuth.getAccessToken();
        if (typeof accessToken === 'undefined') throw new Error('fetchTotalPurchases: Access token required');

        const totalPurchases = await UserService.getTotalPurchases(accessToken);
        return totalPurchases;
    }, [oktaAuth]);

    // ***************** Initialise new user *****************

    const initialiseNewUser = async (profile: Partial<CustomerResponseProfile>) => {
        try {
            const accessToken = oktaAuth.getAccessToken();
            if (typeof accessToken === 'undefined') throw new Error('initialiseNewUser: Access token required');
            // No need display loader, callee to handle loading state

            await initialiseAndSetUserDetails(accessToken, profile);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        }
    };

    // ***************** Refreshers *****************

    const refreshUserPreferences = async () => {
        try {
            const accessToken = oktaAuth.getAccessToken();
            if (typeof accessToken === 'undefined') throw new Error('refreshUserPreferences: Access token required');
            // Display loader
            setLoading(true);

            await fetchAndSetUserPreferences(accessToken);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const refreshUserDetails = async () => {
        try {
            const accessToken = oktaAuth.getAccessToken();
            if (typeof accessToken === 'undefined') throw new Error('refreshUserDetails: Access token required');
            // Display loader
            setLoading(true);

            await fetchAndSetUserDetails(accessToken);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    // ***************** Initial fetch for user data *****************

    const initialiseUserContext = useCallback(async () => {
        if (isInitialising.current) {
            return;
        }
        isInitialising.current = true;

        try {
            const accessToken = oktaAuth.getAccessToken();
            if (typeof accessToken === 'undefined') throw new Error('initialiseUserContext: Access token required');
            // Display loader
            setInitialised(false);
            setLoading(true);

            // Update state with access token
            setAccessToken(accessToken);

            await fetchAndSetUserDetails(accessToken);

            await fetchAndSetDependants(accessToken);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        } finally {
            isInitialising.current = false;
            setInitialised(true);
            setLoading(false);
        }
    }, [fetchAndSetUserDetails, oktaAuth, fetchAndSetDependants]);

    // ***************** Side effects *****************

    // Purely for debugging
    useEffect(() => {
        if (env === 'dev' || env === 'test') {
            console.log('STATE', state);
        }
    }, [state]);

    /**
     * On mount subscribe to auth changes and initialise context accordingly based on
     * signed in status of user
     */
    useEffect(() => {
        // Subscribe to changes in auth state on mount
        oktaAuth.authStateManager.subscribe((authState: AuthState) => {
            if (authState.accessToken?.pendingRemove) {
                // Await log out
                setLoading(true);
            } else if (authState.isAuthenticated) {
                // Is logged in
                if (!state.userDetails.personId) {
                    initialiseUserContext();
                } else if (authState.accessToken && authState.accessToken.accessToken !== state.accessToken) {
                    // Updated access token
                    setAccessToken(authState.accessToken.accessToken);
                }
            } else {
                // Not logged in
                setInitialised(true);
                setLoading(false);
            }
        });

        // Call updateAuthState on mount to initialise the authState
        // https://www.npmjs.com/package/@okta/okta-auth-js#authstatemanagerupdateauthstate
        // This initial call will trigger the above subscribe event and call the handler
        oktaAuth.authStateManager.updateAuthState();
    }, [initialiseUserContext, oktaAuth, state.accessToken, state.userDetails]);

    // ***************** Derived user properties *****************

    const userTimeZone = useMemo(
        () =>
            state.userDetails.state
                ? TimezoneByState[state.userDetails.state]
                : // browser time zone
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
        [state.userDetails.state],
    );

    const profileCompleted = useMemo(() => {
        const { dob, gender, firstName, lastName } = state.userDetails;
        return !!(dob && gender && firstName && lastName);
    }, [state.userDetails]);

    const residencyCompleted = useMemo(() => {
        const { residencyStatusType, state: stateOfResidence } = state.userDetails;
        return !!(residencyStatusType && residencyStatusType.length && stateOfResidence);
    }, [state.userDetails]);

    // ***************** Lazy Dependencies *****************

    const creditBalance = useCreateLazyDependency(fetchCreditBalance);

    const totalPurchases = useCreateLazyDependency(fetchTotalPurchases);

    // ***************** Render *****************

    const value: IUserContext = {
        ...state,
        setAccessToken,
        setUserDetailsByAttr,
        setUserBankDetails,
        setUserVariables,
        setDependants,
        addDependants,
        updateUserPreference,
        initialiseNewUser,
        refreshUserPreferences,
        refreshUserDetails,
        logout,
        userTimeZone,
        profileCompleted,
        residencyCompleted,
        creditBalance,
        totalPurchases,
        setQuizAnswer,
    };

    const { children, ...passThroughProps } = props;

    return (
        <UserContext.Provider value={value} {...passThroughProps}>
            {state.loading && <LoadingSpinnerOverlay />}
            {state.initialised && children}
        </UserContext.Provider>
    );
};
