import { createMachine } from 'xstate';
import Gender from '../../../../../utils/constants/Gender';
import ResidencyStatusTypes from '../../../../../utils/constants/ResidencyStatusTypes';
import State from '../../../../../utils/constants/State';
import {
    clearDobError,
    clearEmailError,
    clearGenericError,
    setDate,
    setEmail,
    setEmailError,
    setFirstName,
    setGender,
    setGenericError,
    setInvalidDateFormatError,
    setLastName,
    setMinAgeError,
    setMonth,
    setResidencyStatus,
    setState,
    setYear,
} from './actions/profileMachineActions';
import { initialContext, ProfileMachineContext } from './context/profileMachineContext';
import {
    isBelowMinAge,
    isDateFilledIn,
    isEligibleResidencyStatus,
    isEligibleState,
    isEmailFilledIn,
    isEmailValid,
    isFirstNameValid,
    isGenderSelected,
    isInvalidResidencyStatus,
    isInvalidState,
    isLastNameValid,
    isValidDateFormat,
} from './guards/profileMachineGuards';

const profileMachine = createMachine(
    {
        id: 'ProfileMachine',
        schema: {
            context: {} as ProfileMachineContext,
            events: {} as
                | { type: 'UPDATE_EMAIL'; data: string }
                | { type: 'BLUR_EMAIL' }
                | { type: 'UPDATE_FIRST_NAME'; data: string }
                | { type: 'UPDATE_LAST_NAME'; data: string }
                | { type: 'UPDATE_DATE'; data: string }
                | { type: 'UPDATE_MONTH'; data: string }
                | { type: 'UPDATE_YEAR'; data: string }
                | { type: 'BLUR_YEAR' }
                | { type: 'UPDATE_STATE'; data: State }
                | { type: 'UPDATE_RESIDENCY_STATUS'; data: ResidencyStatusTypes }
                | { type: 'UPDATE_GENDER'; data: Gender }
                | { type: 'UPDATE_PROFILE' }
                | { type: 'TRY_AGAIN ' }
                | { type: 'HIDE_EXISTING_ACTIVE_USER_ERROR_MODAL' },
        },
        tsTypes: {} as import('./profileMachine.typegen').Typegen0,
        context: initialContext,
        initial: 'ready',
        states: {
            ready: {
                type: 'parallel',
                states: {
                    email: {
                        initial: 'validateIfEmailFilledIn',
                        states: {
                            idle: {
                                on: {
                                    UPDATE_EMAIL: {
                                        actions: 'setEmail',
                                    },
                                    BLUR_EMAIL: {
                                        target: 'validateIfEmailFilledIn',
                                    },
                                },
                            },
                            valid: {
                                on: {
                                    UPDATE_EMAIL: {
                                        actions: 'setEmail',
                                        target: 'idle',
                                    },
                                    BLUR_EMAIL: {
                                        target: 'validateIfEmailFilledIn',
                                    },
                                },
                            },
                            validateIfEmailFilledIn: {
                                always: [
                                    {
                                        cond: 'isEmailFilledIn',
                                        target: 'validateEmail',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                            validateEmail: {
                                always: [
                                    {
                                        cond: 'isEmailValid',
                                        target: 'valid',
                                    },
                                    {
                                        actions: 'setEmailError',
                                        target: 'displayEmailError',
                                    },
                                ],
                            },
                            displayEmailError: {
                                exit: 'clearEmailError',
                                on: {
                                    UPDATE_EMAIL: {
                                        actions: 'setEmail',
                                        target: 'idle',
                                    },
                                    BLUR_EMAIL: {
                                        target: 'validateIfEmailFilledIn',
                                    },
                                },
                            },
                        },
                    },
                    firstName: {
                        initial: 'validateFirstName',
                        states: {
                            idle: {
                                on: {
                                    UPDATE_FIRST_NAME: {
                                        actions: 'setFirstName',
                                        target: 'validateFirstName',
                                    },
                                },
                            },
                            valid: {
                                on: {
                                    UPDATE_FIRST_NAME: {
                                        actions: 'setFirstName',
                                        target: 'validateFirstName',
                                    },
                                },
                            },
                            validateFirstName: {
                                always: [
                                    {
                                        cond: 'isFirstNameValid',
                                        target: 'valid',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                        },
                    },
                    lastName: {
                        initial: 'validateLastName',
                        states: {
                            idle: {
                                on: {
                                    UPDATE_LAST_NAME: {
                                        actions: 'setLastName',
                                        target: 'validateLastName',
                                    },
                                },
                            },
                            validateLastName: {
                                always: [
                                    {
                                        cond: 'isLastNameValid',
                                        target: 'valid',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                            valid: {
                                on: {
                                    UPDATE_LAST_NAME: {
                                        actions: 'setLastName',
                                        target: 'validateLastName',
                                    },
                                },
                            },
                        },
                    },
                    dob: {
                        initial: 'validateIfDateFilledIn',
                        states: {
                            idle: {
                                on: {
                                    UPDATE_DATE: {
                                        actions: 'setDate',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    UPDATE_MONTH: {
                                        actions: 'setMonth',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    UPDATE_YEAR: {
                                        actions: 'setYear',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    BLUR_YEAR: {
                                        target: 'validateIfDateFilledIn',
                                    },
                                },
                            },
                            validateIfDateFilledIn: {
                                always: [
                                    {
                                        cond: 'isDateFilledIn',
                                        target: 'validateDateFormat',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                            validateDateFormat: {
                                always: [
                                    {
                                        cond: 'isValidDateFormat',
                                        target: 'validateMinAge',
                                    },
                                    {
                                        actions: 'setInvalidDateFormatError',
                                        target: 'displayDobError',
                                    },
                                ],
                            },
                            displayDobError: {
                                exit: 'clearDobError',
                                on: {
                                    UPDATE_DATE: {
                                        actions: 'setDate',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    UPDATE_MONTH: {
                                        actions: 'setMonth',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    UPDATE_YEAR: {
                                        actions: 'setYear',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    BLUR_YEAR: {
                                        target: 'validateIfDateFilledIn',
                                    },
                                },
                            },
                            validateMinAge: {
                                always: [
                                    {
                                        actions: 'setMinAgeError',
                                        cond: 'isBelowMinAge',
                                        target: 'displayDobError',
                                    },
                                    {
                                        target: 'valid',
                                    },
                                ],
                            },
                            valid: {
                                on: {
                                    UPDATE_DATE: {
                                        actions: 'setDate',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    UPDATE_MONTH: {
                                        actions: 'setMonth',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    UPDATE_YEAR: {
                                        actions: 'setYear',
                                        target: 'validateIfDateFilledIn',
                                    },
                                    BLUR_YEAR: {
                                        target: 'validateIfDateFilledIn',
                                    },
                                },
                            },
                        },
                    },
                    state: {
                        initial: 'validateState',
                        states: {
                            validateState: {
                                always: [
                                    {
                                        cond: 'isEligibleState',
                                        target: 'valid',
                                    },
                                    {
                                        cond: 'isInvalidState',
                                        target: 'ineligible',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                            valid: {
                                on: {
                                    UPDATE_STATE: {
                                        actions: 'setState',
                                        target: 'validateState',
                                    },
                                },
                            },
                            ineligible: {
                                on: {
                                    UPDATE_STATE: {
                                        actions: 'setState',
                                        target: 'validateState',
                                    },
                                },
                            },
                            idle: {
                                on: {
                                    UPDATE_STATE: {
                                        actions: 'setState',
                                        target: 'validateState',
                                    },
                                },
                            },
                        },
                    },
                    residencyStatus: {
                        initial: 'validateResidencyStatus',
                        states: {
                            validateResidencyStatus: {
                                always: [
                                    {
                                        cond: 'isEligibleResidencyStatus',
                                        target: 'valid',
                                    },
                                    {
                                        cond: 'isInvalidResidencyStatus',
                                        target: 'ineligible',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                            valid: {
                                on: {
                                    UPDATE_RESIDENCY_STATUS: {
                                        actions: 'setResidencyStatus',
                                        target: 'validateResidencyStatus',
                                    },
                                },
                            },
                            ineligible: {
                                on: {
                                    UPDATE_RESIDENCY_STATUS: {
                                        actions: 'setResidencyStatus',
                                        target: 'validateResidencyStatus',
                                    },
                                },
                            },
                            idle: {
                                on: {
                                    UPDATE_RESIDENCY_STATUS: {
                                        actions: 'setResidencyStatus',
                                        target: 'validateResidencyStatus',
                                    },
                                },
                            },
                        },
                    },
                    gender: {
                        initial: 'validateGender',
                        states: {
                            validateGender: {
                                always: [
                                    {
                                        cond: 'isGenderSelected',
                                        target: 'valid',
                                    },
                                    {
                                        target: 'idle',
                                    },
                                ],
                            },
                            valid: {
                                on: {
                                    UPDATE_GENDER: {
                                        actions: 'setGender',
                                        target: 'validateGender',
                                    },
                                },
                            },
                            idle: {
                                on: {
                                    UPDATE_GENDER: {
                                        actions: 'setGender',
                                        target: 'validateGender',
                                    },
                                },
                            },
                        },
                    },
                },
                on: {
                    UPDATE_PROFILE: {
                        actions: 'clearGenericError',
                        target: '#ProfileMachine.updateProfile',
                    },
                },
            },
            updateProfile: {
                invoke: {
                    src: 'updateCustomerProfile',
                    onDone: [
                        {
                            actions: ['updateUserContext', 'notifyProfileUpdateComplete'],
                            target: 'ready',
                        },
                    ],
                    onError: [
                        {
                            actions: 'setGenericError',
                            target: 'ready',
                        },
                    ],
                },
            },
            showExistingActiveUserErrorModal: {
                on: {
                    HIDE_EXISTING_ACTIVE_USER_ERROR_MODAL: {
                        target: 'ready',
                    },
                },
            },
        },
    },
    {
        guards: {
            isEmailFilledIn,
            isEmailValid,
            isFirstNameValid,
            isLastNameValid,
            isDateFilledIn,
            isValidDateFormat,
            isBelowMinAge,
            isEligibleState,
            isInvalidState,
            isEligibleResidencyStatus,
            isInvalidResidencyStatus,
            isGenderSelected,
        },
        actions: {
            setEmail,
            setEmailError,
            clearEmailError,
            setFirstName,
            setLastName,
            setDate,
            setMonth,
            setYear,
            setInvalidDateFormatError,
            setMinAgeError,
            clearDobError,
            setState,
            setResidencyStatus,
            setGender,
            setGenericError,
            clearGenericError,
        },
    },
);

export default profileMachine;
