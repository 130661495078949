interface Headers {
    headers: {
        Authorization: string;
        'content-type': string;
        Accept: string;
    };
}

const toHeaders = (accessToken?: string, config?: Record<string, any>): Headers => ({
    headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
        ...config,
    },
});

export default toHeaders;
