import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import CdnService from '../../services/cdn-service/CdnService';
import ProductService from '../../services/product-service/ProductService';
import withRetriesAsync from '../../services/utils/withRetriesAsync';
import { AllActivitiesConfig } from '../models/ActivitiesConfig';
import { CoverCategory } from '../models/cdn-content/CoverCategory';
import { AllCoverInformation } from '../models/cdn-content/CoverInformation';
import { AllCoverTerms } from '../models/cdn-content/CoverTerms';
import { AllFlipContent } from '../models/cdn-content/FlipContent';
import { AllCoverConfig } from '../models/CoverConfig';
import ProductGroupsResponse from '../models/ProductGroupsResponse';
import ProductResponse from '../models/ProductResponse';

// Interfaces
interface ProductState {
    loading: boolean;
    initialised: boolean;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    coverInformation: AllCoverInformation;
    coverCategories: CoverCategory[];
    coverConfig: AllCoverConfig;
    coverTerms: AllCoverTerms;
    activities: AllActivitiesConfig;
    error: boolean;
}

interface ProductActions {
    refreshProductsAndContent: () => Promise<void>;
}

const initialState: ProductState = {
    loading: false,
    initialised: false,
    products: [],
    productGroups: [],
    coverInformation: {},
    coverCategories: [],
    coverTerms: {},
    coverConfig: {},
    activities: [],
    error: false,
};

export type ProductDispatchAction =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_INITIALISED'; payload: boolean }
    | { type: 'SET_PRODUCTS'; payload: ProductResponse[] }
    | { type: 'SET_PRODUCTS_ERROR'; payload: boolean }
    | { type: 'SET_ALL_CONTENT'; payload: AllFlipContent };

export type IProductContext = ProductState & ProductActions;

export const ProductContext = createContext<any>({});

export const useProduct = (): IProductContext => {
    const context: IProductContext = useContext(ProductContext);
    if (typeof context === 'undefined') {
        throw new Error('Product Context must be used within the ProductContext');
    }
    return context;
};

const reducer = (state: ProductState, action: ProductDispatchAction): ProductState => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_INITIALISED':
            return { ...state, initialised: action.payload };
        case 'SET_PRODUCTS':
            return { ...state, products: action.payload };
        case 'SET_PRODUCTS_ERROR':
            return { ...state, error: action.payload };
        case 'SET_ALL_CONTENT':
            return {
                ...state,
                productGroups: action.payload.productGroupConfig,
                coverInformation: action.payload.coverInformationV2,
                coverCategories: action.payload.coverCategories,
                coverConfig: action.payload.coverConfig,
                coverTerms: { ...action.payload.coverTermsV2, ...action.payload.partnerEventCoverInformation },
                activities: action.payload.activities,
            };
        default:
            throw new Error();
    }
};

export const ProductProvider: React.FC = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { showTestProducts } = useFlags();
    const filterTestProducts = useMemo(() => showTestProducts !== true, [showTestProducts]);

    const setProductsLoading = (loading: boolean) => {
        dispatch({ type: 'SET_LOADING', payload: loading });
    };
    const setProductsInitialised = (initialised: boolean) => {
        dispatch({ type: 'SET_INITIALISED', payload: initialised });
    };
    const setProducts = (products: ProductResponse[]) => {
        dispatch({ type: 'SET_PRODUCTS', payload: products });
    };

    const setAllContent = (cdnContent: AllFlipContent) => {
        dispatch({ type: 'SET_ALL_CONTENT', payload: cdnContent });
    };

    const setProductsError = (error: boolean) => {
        dispatch({ type: 'SET_PRODUCTS_ERROR', payload: error });
    };
    // ***************** Fetchers *****************

    const fetchAllProducts = useCallback(async () => {
        const response = await ProductService.getProducts({
            filterTestProducts,
        });
        return response;
    }, [filterTestProducts]);

    const fetchAllContent = useCallback(async (): Promise<AllFlipContent> => {
        const response = await CdnService.getAllContent({});
        return response;
    }, []);

    // ***************** Fetch and set *****************

    // ***************** Private calls *****************
    // Try catch handled in fetchAndSetAll

    const fetchAndSetProducts = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchAllProducts());
        setProducts(response);
    }, [fetchAllProducts]);

    const fetchAndSetAllContent = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchAllContent());
        setAllContent(response);
    }, [fetchAllContent]);

    // ***************** Main fetch and set *****************

    const fetchAndSetAllProducts = useCallback(async () => {
        setProductsLoading(true);

        try {
            await Promise.all([fetchAndSetProducts(), fetchAndSetAllContent()]);
            setProductsInitialised(true);
        } catch (e) {
            setProductsError(true);
        } finally {
            setProductsLoading(false);
        }
    }, [fetchAndSetProducts, fetchAndSetAllContent]);

    // ***************** Refreshers *****************
    const refreshProductsAndContent = async () => {
        try {
            setProductsLoading(true);

            await fetchAndSetAllProducts();
        } catch (e) {
            setProductsError(true);
            console.error(e);
        } finally {
            setProductsLoading(false);
        }
    };
    // ***************** Initialise *****************

    useEffect(() => {
        fetchAndSetAllProducts();
    }, [fetchAndSetAllProducts]);

    const value: IProductContext = {
        ...state,
        refreshProductsAndContent,
    };

    const { children, ...passThroughProps } = props;
    return (
        <ProductContext.Provider value={value} {...passThroughProps}>
            {state.loading && <LoadingSpinnerOverlay />}
            {state.error && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            {/* Block rendering of children if product context isn't initialised */}
            {state.initialised && children}
        </ProductContext.Provider>
    );
};
