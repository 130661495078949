import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse from '../../business-logic/models/CustomerResponse';
import transformCustomerResponse, { CustomerDetails } from './transformCustomerResponse';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getCustomer = async ({
    accessToken,
    axios,
}: {
    accessToken: string;
} & ServiceArgs): Promise<CustomerDetails | null> => {
    try {
        const { data } = await axios.get<CustomerResponse>(`${baseApiPath}/api/v1/customer`, toHeaders(accessToken));

        return transformCustomerResponse(data);
    } catch (error) {
        if (isAxios404Error(error)) {
            return null; // Customer not found
        }

        throw error;
    }
};

export default asService(getCustomer);
