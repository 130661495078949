import { datadogRum } from '@datadog/browser-rum';
import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse from '../../business-logic/models/CustomerResponse';
import transformCustomerResponse, { CustomerDetails } from './transformCustomerResponse';
import withRetriesAsync from '../utils/withRetriesAsync';
import isAxios409Error from '../utils/isAxios409Error';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const initialiseCustomer = async ({
    accessToken,
    axios,
}: {
    accessToken: string;
} & ServiceArgs): Promise<CustomerDetails & { isNewCustomer: boolean }> => {
    const { data, status } = await withRetriesAsync(
        () => axios.post<CustomerResponse>(`${baseApiPath}/api/v1/customer`, {}, toHeaders(accessToken)),
        undefined,
        (error) => {
            // If 409, log error and retry fetch
            if (isAxios409Error(error)) {
                datadogRum.addError(error, {
                    serviceName: 'initialiseCustomer',
                    description: 'conflict in customer creation call',
                });
                return true;
            }
            return false;
        },
    );

    return { ...transformCustomerResponse(data), isNewCustomer: status === 201 };
};

export default asService(initialiseCustomer);
