import { useEffect, useState, useRef, RefObject } from 'react';

export default function useOnScreen(ref: RefObject<HTMLElement>) {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef<IntersectionObserver>(
        new IntersectionObserver(([entry]) => {
            setIsOnScreen(entry.intersectionRatio < 1);
        }, { threshold: 1 }),
    );

    useEffect(() => {
        if (ref.current && observerRef.current) {
            observerRef.current.observe(ref.current);

            return () => {
                observerRef.current.disconnect();
            };
        }
        return undefined;
    }, [ref]);

    return isOnScreen;
}
