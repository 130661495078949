import AnalyticsMetadataService from '../../../../../services/analytics-metadata-service/AnalyticsMetadataService';
import { ReviewCoverMachineContextTypes } from '../context/reviewCoverMachineContext';

export type ReviewCoverMachineMachineServices = {
    setMetadata: {
        data: void;
    };
};

export const setMetadata = async (ctx: ReviewCoverMachineContextTypes): Promise<void> => {
    if (ctx.purchaseState?.destination) {
        await AnalyticsMetadataService.updateDestinationsMetadata({
            accessToken: ctx.accessToken,
            destinationMetadata: ctx.cartItemsByCoverCode
                .map((x) => x.coverItems)
                .flat()
                .map((y) => {
                    return {
                        insuranceCoverId: y.insuranceCoverId ?? '',
                        destination: {
                            destinations: ctx.purchaseState?.destination?.destinations ?? [],
                            startingRegion: {
                                region: ctx.purchaseState?.destination?.startingRegion ?? '',
                                timeZone: ctx.purchaseState?.destination?.timezone ?? '',
                            },
                        },
                    };
                }),
        });
    }
};
