import { datadogRum } from '@datadog/browser-rum';
import { METADATA_CATEGORY } from '../../business-logic/models/Metadata';
import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const updateDestinationsMetadata = async ({
    accessToken,
    destinationMetadata,
    axios,
}: {
    accessToken: string;
    destinationMetadata: {
        insuranceCoverId: string;
        destination: {
            destinations: string[];
            startingRegion: {
                region: string;
                timeZone: string;
            };
        };
    }[];
} & ServiceArgs): Promise<void> => {
    try {
        assertAccessTokenValidity(accessToken);
        await withRetriesAsync(() =>
            axios.post(
                `${baseApiPath}/api/v1/analytics-metadata/metadata`,
                {
                    items: destinationMetadata.map((e) => ({
                        entityId: e.insuranceCoverId,
                        metadataCategory: METADATA_CATEGORY.insuranceCoverDestinations,
                        data: {
                            destination: e.destination,
                        },
                    })),
                },
                toHeaders(accessToken),
            ),
        );
    } catch (error) {
        datadogRum.addError(error, {
            serviceName: 'analyticsMetadata',
            description: 'error in analytics metadata call',
        });
    }
};

export default asService(updateDestinationsMetadata);
