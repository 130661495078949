import { useOktaAuth } from '@okta/okta-react';
import { useMachine } from '@xstate/react';
import { FC, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { CartErrorDisplay, CartInstanceResponse } from '../../../../business-logic/models/Cart';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import { ReviewCoverSelection } from '../../../../business-logic/models/ReviewCoverSelection';
import Alert from '../../../../components/alert/Alert';
import Button from '../../../../components/button/Button';
import CartError from '../../../../components/cart-error/CartError';
import CoverSummaryCard from '../../../../components/cover-summary-card/CoverSummaryCard';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import ReviewCoverFinePrint from '../../../../components/review-cover-fine-print/ReviewCoverFinePrint';
import Sticky from '../../../../components/sticky/Sticky';
import guestCartContent from '../../../../content/ui/screens/guest-cart/guestCart';
import withContent from '../../../../hoc/with-content/withContent';
import useCart from '../../../../hooks/use-cart/useCart';
import common from '../../../../strings/common';
import { PurchaseState } from '../../../../types/PurchaseState';
import formatPrice from '../../../../utils/formatPrice';
import getCartRequestItems from '../../../../utils/getCartRequestItems';
import reviewCoverMachine from './review-cover-machine/reviewCoverMachine';

import './ReviewCoverStep.scss';

const contentMap = {
    benefitsCTA: 'ui.reviewCoverStep.benefitsCTA',
    exclusionsCTA: 'ui.reviewCoverStep.exclusionsCTA',
    ctaLabel: 'ui.reviewCoverStep.ctaLabel',
    coverSummaryTitle: 'ui.reviewCoverStep.coverSummaryTitle',
    subtotal: 'ui.reviewCoverStep.subtotal',
    total: 'ui.reviewCoverStep.total',
};

interface ReviewCoverStepProps {
    content: Record<keyof typeof contentMap, string>;
    purchaseState: PurchaseState;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    userTimeZone: string;
    coverInformation: AllCoverInformation | null;
    insuredPersons: {
        accountHolder: boolean;
        dependants: string[];
    };
    onContinueToPayment: () => void;
}

const ReviewCoverStep: FC<ReviewCoverStepProps> = ({
    content,
    purchaseState,
    products,
    productGroups,
    userTimeZone,
    coverInformation,
    insuredPersons,
    onContinueToPayment,
}) => {
    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onContinueToPayment();
    };

    const { createCart } = useCart();
    const history = useHistory();
    const { authState } = useOktaAuth();
    const [state, send] = useMachine(reviewCoverMachine, {
        context: {
            accessToken: authState?.accessToken?.accessToken,
            purchaseState,
            products,
            productGroups,
            coverInformation,
            userTimeZone,
            insuredPersons,
        },
        actions: {
            openCart: () => {
                createCart.mutate(
                    getCartRequestItems(purchaseState, products, productGroups, userTimeZone, insuredPersons),
                    {
                        onError: (err) => {
                            send({
                                type: 'CART_CREATE_ERROR',
                                data: (err as Error).cause as CartErrorDisplay,
                            });
                        },
                        onSuccess: (data) => {
                            send({ type: 'CART_CREATED', data: data as CartInstanceResponse });
                        },
                    },
                );
            },
        },
    });
    if (state.hasTag('initialising')) {
        return <LoadingSpinner />;
    }

    return (
        <form onSubmit={handleFormSubmit}>
            {state.matches('readyToContinue') && (
                <div>
                    {state.context.cartItemsByCoverCode.map((coverCodeGroup, index) => (
                        <div key={coverCodeGroup.selectedCoverCode}>
                            <CoverSummaryCard
                                className={index > 0 ? 'cover-summary-card--multiple' : undefined}
                                subtotal={coverCodeGroup.subTotalByCoverCode}
                                checkoutChipText={coverCodeGroup.checkoutChipText}
                                coverName={coverCodeGroup.name}
                                unitLabel={coverCodeGroup.unitLabel!}
                                hideUnitCount={coverCodeGroup.hideUnitCount}
                                coverType={coverCodeGroup.coverType}
                                paymentModelDescription={coverCodeGroup.paymentModelDescription!}
                                coverSelections={coverCodeGroup.coverItems as ReviewCoverSelection[]}
                                unitPrice={coverCodeGroup.unitPrice}
                            />
                        </div>
                    ))}

                    <p className="review-cover-step__total">
                        {content.total}
                        <span className="review-cover-step__total-amount">{formatPrice(state.context.cartTotal)}</span>
                    </p>
                    <ReviewCoverFinePrint ctaLabel={content.ctaLabel} />
                </div>
            )}
            <Sticky>
                {state.hasTag('error') && !!state.context.cartError && (
                    <CartError
                        cartError={state.context.cartError as CartErrorDisplay}
                        tryAgainEvent={() => send({ type: 'CART_TRY_AGAIN' })}
                        prevStepEvent={() => history.goBack()}
                    />
                )}
                {state.hasTag('error') && !state.context.cartError && (
                    <>
                        <Alert
                            message={common.errorSomethingWentWrongTryAgain}
                            className="review-cover-step__metadata-error"
                        />
                        <Button
                            variant="primary"
                            width="full"
                            size="small"
                            label="Try again"
                            onClick={() => send({ type: 'METADATA_TRY_AGAIN' })}
                        />
                    </>
                )}
                <div>
                    {state.matches('readyToContinue') && <Button type="submit" width="full" label={content.ctaLabel} />}
                </div>
            </Sticky>
        </form>
    );
};

export default withContent(ReviewCoverStep, contentMap, guestCartContent);
