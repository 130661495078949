export const METADATA_CATEGORY = {
    purchaseActivities: 'purchaseActivities',
    insuranceCoverDestinations: 'insuranceCoverDestination',
} as const;

export interface DestinationMetadata {
    destination: {
        destinations: string[];
        startingRegion: {
            region: string;
            timeZone: string;
        };
    };
}

export interface PurchaseActivityMetadata {
    activities: string[];
}

export interface Metadata {
    entityId: string;
    metadataCategory: (typeof METADATA_CATEGORY)[keyof typeof METADATA_CATEGORY];
    data: DestinationMetadata | PurchaseActivityMetadata;
}
